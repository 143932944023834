import { Container, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import Fresher from './../../assets/images/fresher.png';
import FreeShip from './../../assets/images/free-ship.png';
import Security from './../../assets/images/security.png';
import Supporter from './../../assets/images/support-24-24.png';
import styled from 'styled-components';

const CommitSection = styled.section`
    background: #f2c123;
    & .item {
        padding-top: 25px;
        padding-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        & .thumb {
            display: block;
            margin-right: 15px;
        }
        & p {
            color: #fff;
            & span {
                display: block;
                @media (max-width: 768px) {
                    display: inline;
                }
            }
        }
        @media (max-width: 768px) {
            justify-content: flex-start;
            margin-left: 10px;
        }
       
    }
`;

class Commit extends Component {
    render() {
        return (
            <CommitSection>
                <Container>
                    <Grid container>
                        <Grid item xl={3} md={6} xs={12}>
                            <div className="item">
                                <div className="thumb">
                                    <img src={Fresher} alt="Hoàn trả không gặp rắc rối" />
                                </div>
                                <div className="for-desktop">
                                    <p>Hoàn trả không gặp rắc rối</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xl={3} md={6} xs={12}>
                            <div className="item">
                                <div className="thumb">
                                    <img src={Security} alt="Giao dịch an toàn" />
                                </div>
                                <div>
                                    <p>Giao dịch an toàn</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xl={3} md={6} xs={12}>
                            <div className="item">
                                <div className="thumb">
                                    <img src={FreeShip} alt="Vận chuyển cực kỳ nhanh chóng" />
                                </div>
                                <div>
                                    <p>Giao hàng 24/7<span>Vận chuyển cực kỳ nhanh chóng</span></p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xl={3} md={6} xs={12}>
                        <div className="item">
                                <div className="thumb">
                                    <img src={Supporter} alt="Đảm bảo chất lượng" />
                                </div>
                                <div>
                                    <p>Đảm bảo chất lượng</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </CommitSection>
        );
    }
}

export default Commit;