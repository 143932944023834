const style = (theme) => ({
    viewPost_detail: {
        padding: '15px 0px 45px',
        fontFamily: "'Roboto black', sans-serif",
        "& .viewPost_title h1": {
            paddingTop: "20px",
            fontSize: "32px",
            lineHeight: "45px",
            fontWeight: "bold",
        },
        "& .viewPost_info": {
            marginTop: '20px',
            marginBottom: '26px',
            fontSize: '17px',
            "& .viewPost_createdAt":{
                display: 'inline-block',
                marginRight: '30px',
                "& span": {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                }
            },
            "& .viewPost_fullName": {
                display: 'inline-block',
                "& span": {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end'
                }
            }
        },
        "& .viewPost_description": {
            "& p": {
                fontSize: '16px',
                lineHeight: '23px'
            },
            "& figure.image": {
                margin: '0px auto',
                display: 'block',
                [theme.breakpoints.down("md")]: {
                    width: '100%!important',
                },
                "& img": {
                    margin: '0px auto',
                    display: 'block',
                }
            }
        }
    }
});
export default style;