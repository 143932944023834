import React, { Component } from "react";
import Banner from "./components/Banner";
import FeaturedProduct from "./components/FeaturedProduct";
import MiddleBanner from "./components/MiddleBanner";
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { getProductList, productFavorite } from "../../models/product";
import CategoryProduct from "../../components/CategoryProduct";
import moment from "moment";
import { getBanner } from "../../models/banner";
import { Helmet } from "react-helmet";
import LocalStorageService from "../../services/LocalStorageService";
import AlertMessage from "../../components/UI/AlertMessage";
import { apiDomain } from "../../utills/config";
import ListPost from "./components/ListPost";
import { queryPostList } from "../../models";
import { addProductFavorite } from "../../models/product";
import BannerTopBlog from "./components/BannerTopBlog";

const localStorageService = LocalStorageService.getService();


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listNewProduct: [],
      listBestSellerProduct: [],
      listBestDiscountProduct: [],
      isLoading: false,
      listTopBanner: [],
      listTopBannerMobile: [],
      listMiddleBanner: [],
      listBottomBanner: [],
      listHighlightProduct: [],
      total_items_discount: 0,
      isChangeCart: false,
      open: false,
      sbColor: 'error',
      sbMessage: '',
      listBlog: [],
      total_blogs: 0,
      isAuth: false,
      itemHighLight: {},
      itemHighLightMobile: {},
      itemBestSeller: {},
      itemBestSellerMobile: {},
      itemBestDiscount: {},
      itemBestDiscountMobile: {},
      itemNewProduct: {},
      itemNewProductMobile: {},
      isLoadingTopBanner: false
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true, isLoadingTopBanner: true });
    let token = localStorageService.getAccessToken();
    if(typeof token === "string") {
      await this.setState({isAuth: true});
    }
    if(localStorageService.getOrderInfo()
    && typeof JSON.parse(localStorageService.getOrderInfo()) === "object"
    && Object.keys(JSON.parse(localStorageService.getOrderInfo())).length > 0) {
      this.props.clearListCart();
      localStorageService.clearCart();
      localStorageService.clearVoucher();
      localStorageService.clearOrderInfo();
      localStorageService.clearDeliveryInfo();
      localStorageService.clearVoucherPoint();
    }
    this.getListBanner();
  }

  getListBanner = () => {
    getBanner({})
      .then((res) => {
        if (res.success) {
          let items = [];
          let itemsTop = [];
          let itemsTopMobile = [];
          let itemsMiddle = [];
          let itemsBottom = [];
          let itemHighLight = {};
          let itemHighLightMobile = {};
          let itemBestSeller = {};
          let itemBestSellerMobile = {};
          let itemBestDiscount = {};
          let itemBestDiscountMobile = {};
          let itemNewProduct = {};
          let itemNewProductMobile = {};
          if(res._embedded.banner && Array.isArray(res._embedded.banner) && res._embedded.banner.length > 0){
            let count = res._embedded.banner.length;
            for (let i = 0; i < count; i++) {
              let item = {
                link: res._embedded.banner[i].link,
                image: res._embedded.banner[i].image,
                name: res._embedded.banner[i].name,
                sort: res._embedded.banner[i].sort,
                type: res._embedded.banner[i].type,
              };
              items.push(item);
            }
            itemsTop = items.filter((value) => value.type === "top").sort((a, b) => a.sort - b.sort);
            itemsTopMobile = items.filter((value) => value.type === "top-mobile").sort((a, b) => a.sort - b.sort);
            itemsMiddle = items.filter((value) => value.type === "middle").sort((a, b) => a.sort - b.sort);
            itemsBottom = items.filter((value) => value.type === "bottom").sort((a, b) => a.sort - b.sort);
            itemHighLight = items.find((value) => value.type === "highlights");
            itemHighLightMobile = items.find((value) => value.type === "highlights-mobile");
            itemBestSeller = items.find((value) => value.type === "hot");
            itemBestSellerMobile = items.find((value) => value.type === "hot-mobile");
            itemBestDiscount = items.find((value) => value.type === "sale-off");
            itemBestDiscountMobile = items.find((value) => value.type === "sale-off-mobile");
            itemNewProduct = items.find((value) => value.type === "new");
            itemNewProductMobile = items.find((value) => value.type === "new-mobile");
          }

          this.setState({
            listTopBanner: itemsTop ? itemsTop : [],
            listTopBannerMobile: itemsTopMobile ? itemsTopMobile : [],
            listMiddleBanner: itemsMiddle ? itemsMiddle : [] ,
            listBottomBanner: itemsBottom ? itemsBottom : [],
            itemHighLight: itemHighLight ? itemHighLight : {},
            itemHighLightMobile: itemHighLightMobile ? itemHighLightMobile : {},
            itemBestSeller: itemBestSeller ? itemBestSeller : {},
            itemBestSellerMobile: itemBestSellerMobile  ? itemBestSellerMobile : {},
            itemBestDiscount: itemBestDiscount ? itemBestDiscount : {},
            itemBestDiscountMobile: itemBestDiscountMobile ? itemBestDiscountMobile : {},
            itemNewProduct: itemNewProduct ? itemNewProduct : {},
            itemNewProductMobile: itemNewProductMobile ? itemNewProductMobile : {},
            isLoadingTopBanner: false
          });
        }
      })
      .then(() => {
        this.getFeaturedProduct();
      });
  };

  getListPost = () => {
    let params = {
      page: 1,
      page_size: 3
    }
    queryPostList(params).then((res) => {
      if(res.success) {
        let posts = res._embedded.post ? res._embedded.post : [];
        this.setState({listBlog: posts, total_blogs: res.total_items, isLoading: false})
      }
    })
  }

  async getFeaturedProduct() {
    let favorite = [];
    if(this.state.isAuth){
      await productFavorite({}).then((rsFavorite) => {
        if(rsFavorite.success) {
          if(rsFavorite.products) {
            favorite = rsFavorite.products
          }
        }
      })
    }
    getProductList({
      page: 1,
      page_size: 8,
      highlight: 1,
    }).then((res) => {
      if (res.success) {
        let result = this.handleGetInfoProduct(favorite, res.products, res.exchange_rate);
        this.setState({
          ...this.state,
          listHighlightProduct: result,
          isLoading: false,
        });
      }
    });

    getProductList({
      page: 1,
      page_size: 8,
      sort_type: "desc",
      sort_name: "id",
    }).then((res) => {
      if (res.success) {
        let result = this.handleGetInfoProduct(favorite, res.products, res.exchange_rate);
        this.setState({
          ...this.state,
          listNewProduct: result,
          isLoading: false,
        });
      }
    });

    getProductList({
      page: 1,
      page_size: 8,
      bestseller: 1,
    }).then((res) => {
      if (res.success) {
        let result = this.handleGetInfoProduct(favorite, res.products, res.exchange_rate);
        this.setState({
          ...this.state,
          listBestSellerProduct: result,
          isLoading: false,
        });
      }
    });

    getProductList({
      page: 1,
      page_size: 8,
      bestdiscount: 1,
    }).then((res) => {
      if (res.success) {
        let result = this.handleGetInfoProduct(favorite, res.products, res.exchange_rate);
        this.setState({
          ...this.state,
          listBestDiscountProduct: result,
          isLoading: false,
          total_items_discount: res.total_items
        });
      }
    });
    this.getListPost();
  }

  handleGetInfoProduct = (favorite, products, exchange) => {
    const result = [];
    products && Array.isArray(products) && products.length > 0 && products.forEach((value) => {
      let isFavorite = false;
      favorite.forEach((f) => {
        if(f.id === value.id){
          isFavorite = true;
        }
      });
      value.isFavorite = isFavorite;
      if (value.created_at) {
        let created_at = value.created_at.split(" ");
        let created_at_date = created_at[0].split("/");
        let created_at_format = `${created_at_date[2]}-${created_at_date[1]}-${created_at_date[0]}`;
        if (moment().diff(created_at_format, "days") === 0) {
          value.isNew = true;
        }
      }
      if(exchange && typeof exchange === "object" && Object.keys(exchange).length > 0) {
        value.isExchange = true;
        value.symbol = exchange.symbol;
        value.margin = exchange.margin;
        value.margin_type = exchange.margin_type;
        value.rate = exchange.rate;
      }
      if (value && value.attribute_detail.length > 0) {
        value.attribute_detail.sort(function (a, b) {
          return Number(a.price) - Number(b.price);
        });
        let lengthAttr = value.attribute_detail.length;
        value.multiPrice = [
          Number(value.attribute_detail[0].price),
          Number(value.attribute_detail[lengthAttr - 1].price),
        ];
        value.multiDiscountPrice = [
          Number(value.attribute_detail[0].discount_price),
          Number(value.attribute_detail[lengthAttr - 1].discount_price),
        ];
      }
      result.push(value);
    });
    return result;
  }

  reloadListProduct = () => {
    this.setState(prevState => ({
      isChangeCart: !prevState.isChangeCart
    }));
  }

  showMessage = (info) => {
    this.setState({open: info.open, sbMessage: info.sbMessage, sbColor: info.sbColor})
  }

  handleAlertClose = () => {
    this.setState({ open: false });
  };

  handleToggleFavorite = (id, type) => {
    if(!this.state.isAuth){
      this.props.history.push('/auth/sign-in');
      return;
    }
    let listBestSellerProduct = [...this.state.listBestSellerProduct];
    let listHighlightProduct = [...this.state.listHighlightProduct];
    let listNewProduct = [...this.state.listNewProduct];
    let listBestDiscountProduct = [...this.state.listBestDiscountProduct];

    let newListBestSellerProduct = listBestSellerProduct.map((value) => {
      if(Number(value.id) === Number(id)){
        value.isFavorite = type === "remove" ? false : true;
      }
      return value;
    });
    let newListHighlightProduct = listHighlightProduct.map((value) => {
      if(Number(value.id) === Number(id)){
        value.isFavorite = type === "remove" ? false : true;
      }
      return value;
    });
    let newListNewProduct = listNewProduct.map((value) => {
      if(Number(value.id) === Number(id)){
        value.isFavorite = type === "remove" ? false : true;
      }
      return value;
    });
    let newListBestDiscountProduct = listBestDiscountProduct.map((value) => {
      if(Number(value.id) === Number(id)){
        value.isFavorite = type === "remove" ? false : true;
      }
      return value;
    });
    addProductFavorite({product_id: id, favorite: type === "remove" ? 0 : 1}).then((res) => {
      if(res.success){
        this.setState({
          open: true,
          sbColor: 'success',
          sbMessage: type === "remove" ? 'Đã xoá khỏi danh sách sản phẩm yêu thích' : 'Đã thêm vào danh sách sản phẩm yêu thích'})
      }
    });
    this.setState({
      listBestSellerProduct: newListBestSellerProduct,
      listHighlightProduct: newListHighlightProduct,
      listNewProduct: newListNewProduct,
      listBestDiscountProduct: newListBestDiscountProduct
    });
  }

  render() {
    const {
      listBestSellerProduct,
      listNewProduct,
      listBestDiscountProduct,
      isLoading,
      isLoadingTopBanner,
      listTopBanner,
      listTopBannerMobile,
      listMiddleBanner,
      listBottomBanner,
      listHighlightProduct,
      total_items_discount,
      isChangeCart,
      open,
      sbColor,
      sbMessage,
      listBlog,
      total_blogs,
      itemHighLight,
      itemHighLightMobile,
      itemBestDiscount,
      itemBestDiscountMobile,
      itemBestSeller,
      itemBestSellerMobile,
      itemNewProduct,
      itemNewProductMobile
    } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Trang chủ</title>
          <meta property="og:title" content="Trang chủ"></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content="Tạp hoá CZ chuyên cung cấp sỉ và lẻ thực phẩm châu Á trên toàn châu Âu"></meta>
          <meta name="description" content="Tạp hoá CZ chuyên cung cấp sỉ và lẻ thực phẩm châu Á trên toàn châu Âu"></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <Banner isLoading={isLoadingTopBanner} listBanner={listTopBanner} listBannerMobile={listTopBannerMobile} />
        <CategoryProduct />
        <FeaturedProduct
          title={"Sản phẩm nổi bật"}
          listProducts={listHighlightProduct}
          isLoading={isLoading}
          type={"hightlight"}
          reloadListProduct={() => this.reloadListProduct()}
          isChangeCart={isChangeCart}
          showMessage={(info) => this.showMessage(info)}
          handleToggleFavorite={(id, type) => this.handleToggleFavorite(id, type)}
          banner={itemHighLight}
          bannerMobile={itemHighLightMobile}
          positionBanner={'right'}
        />
        <FeaturedProduct
          title={"Sản phẩm khuyến mãi"}
          listProducts={listBestDiscountProduct}
          isLoading={isLoading}
          type={"promotion"}
          total_items={total_items_discount}
          reloadListProduct={() => this.reloadListProduct()}
          isChangeCart={isChangeCart}
          showMessage={(info) => this.showMessage(info)}
          handleToggleFavorite={(id, type) => this.handleToggleFavorite(id, type)}
          banner={itemBestDiscount}
          bannerMobile={itemBestDiscountMobile}
          positionBanner={'left'}
        />
        <FeaturedProduct
          title={"Sản phẩm bán chạy"}
          listProducts={listBestSellerProduct}
          isLoading={isLoading}
          type={""}
          reloadListProduct={() => this.reloadListProduct()}
          isChangeCart={isChangeCart}
          showMessage={(info) => this.showMessage(info)}
          handleToggleFavorite={(id, type) => this.handleToggleFavorite(id, type)}
          banner={itemBestSeller}
          bannerMobile={itemBestSellerMobile}
          positionBanner={'right'}
        />
        <MiddleBanner listBanner={listMiddleBanner} isLoading={isLoading} />
        <FeaturedProduct
          title={"Sản phẩm mới"}
          listProducts={listNewProduct}
          isLoading={isLoading}
          type={"new"}
          reloadListProduct={() => this.reloadListProduct()}
          isChangeCart={isChangeCart}
          showMessage={(info) => this.showMessage(info)}
          handleToggleFavorite={(id, type) => this.handleToggleFavorite(id, type)}
          banner={itemNewProduct}
          bannerMobile={itemNewProductMobile}
          positionBanner={'left'}
        />
        <BannerTopBlog isLoading={isLoading} listBanner={listBottomBanner} />
        <ListPost
          title={"Blog"}
          listBlog={listBlog}
          total_blogs={total_blogs}
          isLoading={isLoading}
        />
        <AlertMessage
          open={open}
          severity={sbColor}
          message={sbMessage}
          handleAlertClose={() => this.handleAlertClose()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(Home)));
